
import { Component, Prop, Vue } from "vue-property-decorator"
import StateChip from "../widgets/common/StateChip.vue"

interface IRTag {
  name: string
  icon: string
}
@Component({
  components: {
    StateChip,
  },
})
export default class RecordSummaryCard extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly subtitle?: string
  @Prop({ required: true }) readonly description!: string
  @Prop({ required: false }) readonly tags?: IRTag[]
  @Prop({ required: false, default: false }) active!: boolean
  @Prop({ required: false }) readonly status?: string
}
