
import { Component, Vue } from "vue-property-decorator"
import MissionUpdates from "@/components/consultant/MissionUpdates.vue"
import TasksWidget from "@/components/tasks/TasksWidget.vue"
import AppPage from "@/components/layout/AppPage.vue"

@Component({
  components: {
    TasksWidget,
    MissionUpdates,
    AppPage,
  },
})
export default class CoreTeamDashboard extends Vue {}
