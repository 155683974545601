
import { Component, Prop, Vue } from "vue-property-decorator"
import RecordSummaryCard from "@/components/content/RecordSummaryCard.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import moment from "moment"
@Component({
  components: {
    RecordSummaryCard,
    Loader,
  },
})
export default class RecordSummaryCardGroup extends Vue {
  @Prop({ required: true }) readonly filter!: FilterType
  @Prop({ required: false, default: false }) readonly hasUpdates?: boolean
  @Prop({ required: false }) readonly loading?: boolean

  timeAgo(date: Date | string) {
    return moment(date).fromNow()
  }

  handleRefreshButtonOnClick() {
    this.$emit("refreshRecords")
  }
}
