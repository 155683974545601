
import { ActivityLog } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import ConsultantActivityItem from "@/components/widgets/timeline/ConsultantActivityItem.vue"

@Component({
  components: {
    ConsultantActivityItem,
  },
})
export default class ConsultantMissionActivityTimeline extends Vue {
  @Prop({ default: () => [] }) readonly logs!: ActivityLog[]
  @Prop({ default: true }) readonly dense?: boolean
  modifiedLogs: any[] = []

  mounted() {
    if (this.logs) {
      this.modifiedLogs = this.logs
    }
  }

  get groupedActivities() {
    return this.groupBy(this.modifiedLogs, "createdAt")
  }

  groupBy(array: any[], key: string): any {
    const groupedByKey = array.reduce((acc: any, value: any) => {
      // Group initialization
      if (!acc[value[key]]) {
        acc[value[key]] = []
      }
      // Grouping
      acc[value[key]].push(value)
      return acc
    }, {})
    return groupedByKey
  }
}
